<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="40%" @click:outside="clickOutside">
      <template v-slot:activator="{ on, attrs }">
        <p id="randomGenerateLot" v-bind="attrs" v-on="on" @click="update"></p>
      </template>
      <v-card style="position:relative;">
        <v-form
          ref="entryForm"
          @submit.prevent="generate"
          style="position: relative;"
        >
          <v-card flat style="text-align:center;margin-bottom:20px;">
            <v-toolbar-title class="overline">
              <p style="font-size: 20px; margin: 0; font-weight: bold;">
                Random Generate
              </p>
            </v-toolbar-title>
          </v-card>

          <v-row no-gutters style="padding: 15px;">
            <v-col
              md="12"
              style="padding: 0;display:flex;justify-content:center;align-items:center;margin-bottom:10px;"
            >
              <div style="padding: 0 10px;width:100%;">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tipe lot
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  style="padding: 0; justify-content:flex-start; align-items:center; display:flex;margin-bottom:10px;"
                >
                  <v-checkbox
                    v-model="form.random_type"
                    value="1"
                    style=" margin:0; height:25px; padding:0;"
                  ></v-checkbox>
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);margin-right:20px;
                      "
                  >
                    Numeric
                  </p>

                  <v-checkbox
                    v-model="form.random_type"
                    value="2"
                    style=" margin:0; height:25px; padding:0;"
                  ></v-checkbox>
                  <p
                    style="
                      margin:0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Aplha numeric
                  </p>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jumlah digit
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    v-model="form.len_digit_number"
                    dense
                    outlined
                    :rules="digitNumberRules"
                    type="number"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Jumlah lot
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    v-model="form.number_of_lot_no"
                    dense
                    outlined
                    type="number"
                    :rules="totalLotRules"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Plant
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-autocomplete
                    v-model="company"
                    :items="getDropdownPlant"
                    item-text="name"
                    item-value="id"
                    return-id
                    outlined
                    dense
                    :readonly="!isAdminHO"
                    :disabled="
                      getUserProfile.level.find(
                        ({ id }) => id === '1' || id === '29'
                      ) === undefined
                    "
                    style="margin: 0"
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Lokasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-autocomplete
                    v-model="form.location"
                    :items="getDropdownLocation"
                    item-text="name"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    style="margin: 0"
                    :rules="locationRules"
                  ></v-autocomplete>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="6" style="padding: 0">
                  <p
                    style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                  >
                    Tanggal pembelian
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0;">
                  <v-text-field
                    v-model="form.purchase_date"
                    clearable
                    dense
                    outlined
                    type="date"
                    name="date"
                    step="1"
                    :rules="purchaseDateRules"
                  />
                </v-col>
              </div>
            </v-col>
          </v-row>

          <div
            flat
            style="text-align:center;"
            class="d-flex justify-center align-center"
          >
            <v-btn
              style="width:45%;"
              color="primary"
              type="submit"
              class="ma-2"
            >
              generate
            </v-btn>

            <v-btn style="width:45%;" color="error" class="ma-2" @click="close">
              Batal
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import buildType from '../../../../services/buildType'
export default {
  props: ['assetDetail'],
  data() {
    return {
      asset: buildType.apiURL('asset'),
      dialog: false,
      check: null,
      company: null,
      form: {
        generate_mode: 2,
        number_of_lot_no: 0,
        len_digit_number: 0,
        random_type: '1',
        product_code: '',
        asset_id: '',
        location: null,
        purchase_date: ''
      },
      digitNumberRules: [],
      purchaseDateRules: [],
      locationRules: [],
      totalLotRules: [],
      isAdminHO: false
    }
  },
  watch: {
    company() {
      this.dropdownLocation(this.company)
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant', 'getDropdownLocation'])
  },
  methods: {
    ...mapActions(['dropdownLocation']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    },
    generate() {
      // multiple rules statement
      this.digitNumberRules = [v => !!v || 'Required', v => v <= 10 || 'Max 10']

      this.purchaseDateRules = [v => !!v || 'Required']
      this.locationRules = [v => !!v || 'Required']
      this.totalLotRules = [v => !!v || 'Required']

      const self = this
      setTimeout(function() {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
    },
    createNewForm() {
      const newForm = {
        generate_mode: this.form.generate_mode,
        number_of_lot_no: Number(this.form.number_of_lot_no),
        len_digit_number: Number(this.form.len_digit_number),
        random_type: Number(this.form.random_type),
        product_code: this.form.product_code,
        asset_id: this.form.asset_id,
        location_id: this.form.location.id,
        location_name: this.form.location.name,
        purchase_date: this.form.purchase_date
      }
      console.log(newForm)
      this.save(newForm)
    },
    save(form) {
      axios
        .post(`${this.asset}asset/lot/generate`, form)
        .then(res => {
          console.log(res)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.$emit('loadPage')
          this.close()
        })
        .catch(err => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    clickOutside() {},
    update() {
      this.form.asset_id = this.assetDetail.id
      this.form.product_code = this.assetDetail.code
      this.company = this.getUserProfile.employee.company.plant_id
      this.dropdownLocation(this.getUserProfile.employee.company.plant_id)
      this.isAdminHO = this.getUserProfile.employee.company.plant_id === '6'
    },
    close() {
      this.form = {
        generate_mode: 2,
        number_of_lot_no: 0,
        len_digit_number: 0,
        random_type: '1',
        product_code: '',
        asset_id: '',
        location: null,
        purchase_date: ''
      }
      this.dialog = false
    }
  }
}
</script>
<style lang="scss" scoped></style>
